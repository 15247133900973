import React, { useMemo, useCallback } from "react"
import { Box } from "@chakra-ui/react"
import { EntityList } from "src/components/EntityList"
import { TsModel } from "src/tsApiDocs/models"
import { TsModelDetail } from "src/components/TsModelDetail"
import { useStore } from "src/stores/useStore"

const renderLabel = (m: TsModel) => <Box>{m.title}</Box>

const renderPanel = (m: TsModel) => <TsModelDetail modelId={m.id} />

const sortComparator = (a: TsModel, b: TsModel): number => {
  if (a.title.startsWith("_")) {
    return b.title.startsWith("_") ? a.title.localeCompare(b.title) : -1
  } else if (b.title.startsWith("_")) {
    return 1
  } else {
    return a.title.localeCompare(b.title)
  }
}

export const TsModelList: React.FC = () => {
  // const { models } = useTsApiDocs()
  const models = useStore(useCallback((state) => state.models, []))
  const instances = useMemo(
    () =>
      models === undefined
        ? undefined
        : models.ids
            .map((id) => models.entities[id]!)
            .filter((m) => !m.autoGenerated)
            .sort(sortComparator),
    [models]
  )
  return (
    <EntityList
      title="Models"
      items={instances ?? []}
      renderLabel={renderLabel}
      renderPanel={renderPanel}
    />
  )
}
